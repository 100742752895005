<template>
  <layout>
    <div class="search-top">
      <div class="contant">
        <div class="contant-top">
          <div class="contant-logo">
            <img src="@/assets/home/logo.png" alt @click="$router.push('/')"/>
            <span>爱玩就上爱租号</span>
          </div>
          <div class="contant-search">
            <input type="text" placeholder="搜你想要的~" v-model="valueList"  />
            <button @click="goSerach">搜索</button>
          </div>
        </div>
        <div class="contant-bottom">
          <div class="contant-hot">
            <img src="@/assets/home/hot.png" alt />
            <span>热门游戏</span>
             <div class="newClass">
              <hotlist></hotlist>
            </div>
          </div>
          <ul>
            <li @click="toClick(0)">首页</li>
            <li @click="toClick(1)">
              爱租号
            </li>
            <li :class="{ actived: isShows == 2 }" @click="toClick(2)">
              工具下载
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="download">
      <div class="download_box w">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>工具下载</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="down_cotent">
          <div class="pc_xz">
            <div class="btt">
              <div class="btts"><span></span>Windows软件</div>
            </div>
            <div class="pc_content">
              <ul class="clearfix">
                <li>
                  <div class="xz_top gyxz">
                    <img src="@/assets/download/app.png" alt="" />
                    <div class="rj_nr">
                      <h5>爱租号客户端（上号器）</h5>
                      <p>更新于：2020-09-09</p>
                    </div>
                    <div class="ljxz"><a href="https://c.ul.cn/8a">立即下载</a></div>
                  </div>
                  <div class="xz_bom clearfix" v-if="false">
                    <div class="xlxz"><i></i><a href="">迅雷下载</a></div>
                    <div class="byxz"><i></i><a href="https://c.ul.cn/8a">备用链接下载</a></div>
                  </div>
                </li>
                <li>
                  <div class="xz_top gyxz">
                    <img src="@/assets/download/app.png" alt="" />
                    <div class="rj_nr">
                      <h5>爱租号客户端备用（上号器）</h5>
                      <p>更新于：2020-09-09</p>
                    </div>
                    <div class="ljxz"><a href="https://c.ul.cn/8O">立即下载</a></div>
                  </div>
                  <!-- <div class="xz_bom clearfix">
                    <div class="xlxz"><i></i><a href="">迅雷下载</a></div>
                    <div class="byxz"><i></i><a href="">备用链接下载</a></div>
                  </div> -->
                </li>
              </ul>
            </div>
          </div>
          <div class="sj_xz">
            <div class="btt">
              <div class="btts">
                <span></span>手机APP <em>（请使用手机自带浏览器扫码下载）</em>
              </div>
              <ul class="sj_content clearfix">
                <!-- <li>
                  <img src="@/assets/download/app.png" alt="" />
                  <div class="rj_nr">
                    <h5>爱租号移动APP</h5>
                    <p>手机租号更方便</p>
                    <p>安卓和IOS通用二维码</p>
                  </div>
                  <div class="rwm"><img src="@/assets/download/azhrwm.png" alt=""></div>
                </li>
                <li>
                  <img src="@/assets/download/app.png" alt="" />
                  <div class="rj_nr">
                    <h5>爱租号移动APP</h5>
                    <p>手机租号更方便</p>
                    <p>
                      <i><img src="@/assets/download/ios.png" alt="" /></i
                      >安卓和IOS通用二维码
                    </p>
                  </div>
                  <div class="rwm"><img src="@/assets/download/azhrwm.png" alt=""></div>
                </li>
                <li>
                  <img src="@/assets/download/app.png" alt="" />
                  <div class="rj_nr">
                    <h5>爱租号移动APP</h5>
                    <p>手机租号更方便</p>
                    <p>
                      <i><img src="@/assets/download/android.png" alt="" /></i
                      >安卓和IOS通用二维码
                    </p>
                  </div>
                  <div class="rwm"><img src="@/assets/download/azhrwm.png" alt=""></div>
                </li> -->
                <li>
                  <img src="@/assets/download/app.png" alt="" />
                  <div class="rj_nr">
                    <h5>爱租号移动APP</h5>
                    <p>安卓快速上号工具</p>
                    <p>
                      <i><img src="@/assets/download/android.png" alt="" /></i
                      >
                      Android
                    </p>
                  </div>
                  <div class="rwm"><img src="@/assets/download/azhrwm.png" alt=""></div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <side-Bar></side-Bar>
  </layout>
</template>

<script>
import layout from "../../components/layout";
import sideBar from "../../components/common/sidebar";
import hotlist from '../../components/hotlist'

export default {
  components: {
    layout,
    sideBar,
    hotlist
  },
  data() {
    return {
      isShows: 2,
      valueList: ''
    };
  },
  methods: {
    toClick(e) {
       if(e == 0){
         this.$router.push({
          path:'/home'
        })
      }else if(e == 1) {
        this.$router.push({
          path:'lobby'
        })
      }
      this.isShows = e;
    },
      goSerach() {
      if (this.valueList) {
        this.$router.push({
          path: "/lobby",
          query: {
            key_word: this.valueList,
          },
        });
      }
    },
    
  }
};
</script>

<style lang="scss" scoped>
.search-top {
  width: 100%;
  background-color: #fff;
  height: 168px;
  .contant {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    .contant-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 40px;
      height: 86px;
      .contant-logo {
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 218px;
          height: 55px;
          margin-right: 18px;
        }
        span {
          font-size: 15px;
        }
      }
      .contant-search {
        width: 480px;
        height: 48px;
        background: #ffffff;
        border: 2px solid #3c7efe;
        border-right: none;
        border-radius: 24px;
        box-sizing: border-box;
        display: flex;
        
        input {
          width: 100%;
          height: 100%;
          border: none;
          outline-style: none;
          border-radius: 24px;
          text-indent: 2em;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #a2a6b8;
        }
        button {
          position: relative;
          width: 120px;
          height: 48px;
          background: #3c7efe;
          border-radius: 24px;
          color: #ffffff;
          font-size: 18px;
          box-sizing: border-box;
          margin-top: -2px;
          padding-left: 20px;
          cursor: pointer;
        }
        button::after {
          position: absolute;
          left: 16px;
          top: 50%;
          transform: translateY(-50%);
          content: "";
          display: block;
          width: 20px;
          height: 20px;
          background: url(../../assets/home/ss.png) no-repeat center center;
          background-size: 100% 100%;
        }
      }
    }
    .contant-bottom {
      display: flex;
      .contant-hot {
        width: 200px;
        height: 42px;
        border-radius: 12px 12px 0 0;
        background-color: #3c7efe;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
            &:hover .newClass{
          max-height: 460px;
          transition: max-height ease-in .3s;
        }
        img {
          width: 16px;
          height: 18px;
          margin-right: 12px;
        }
        span {
          font-size: 16px;
          color: #ffffff;
        }
         .newClass{
          position: absolute;
          top: 42px;
          left: 0;
          max-height: 0;
          min-height: 0;
          overflow: hidden;
          transition: max-height ease-out .4s;
          z-index: 9999;
        }
      }
      ul {
        display: flex;
        align-items: center;
        li {
          font-size: 14px;
          color: #666;
          padding: 0 30px;
          // height: 100%;
          line-height: 17px;
          position: relative;
          cursor: pointer;
        }
        .actived{
          color: #000;
          font-weight: bold;
        }
        .actived:after {
          position: absolute;
          bottom: -9px;
          left: 50%;
          margin-left: -7px;
          display: block;
          content: "";
          width: 14px;
          height: 3px;
          background-color: #3b7dfd;
        }
      }
    }
  }
}
.w {
  width: 1200px;
  margin: 0 auto;
}
.download {
  width: 100%;
  background-color: #f6f7fa;
  padding-bottom: 60px;
}
.download_box {
  .el-breadcrumb {
    padding: 20px 0;
  }
  .down_cotent {
    width: 100%;
    height: 660px;
    // padding-bottom: 60px;
    background: #ffffff;
    border-radius: 12px;
    .pc_content {
      margin: 35px 0 20px 0;
      padding-left: 56px;
      box-sizing: border-box;
      ul {
        li:nth-child(odd) {
          margin-right: 150px;
        }
        li {
          float: left;
          width: 460px;
          height: 110px;
          .xz_bom {
            margin-top: 15px;
            div {
              display: flex;
              align-items: center;
              font-size: 12px;
              color: #848a9e;
              i {
                display: inline-block;
                width: 18px;
                height: 18px;
                margin-right: 10px;
              }
            }
            .byxz {
              margin-left: 40px;
            }
            .xlxz,
            .byxz {
              float: left;
            }
            .xlxz i {
              background: url(../../assets/download/xlxz.png) no-repeat center
                center;
              background-size: 100% 100%;
            }
            .byxz i {
              background: url(../../assets/download/ljxz.png) no-repeat center
                center;
              background-size: 100% 100%;
            }
          }
        }
      }
    }
  }
}
.sj_content {
  padding-left: 21px;
  margin: 35px 0 20px 0;
}
.sj_content li:nth-child(odd) {
  margin-right: 150px;
}
.sj_content li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  float: left;
  width: 460px;
  height: 110px;
  margin-bottom: 35px;
  .rj_nr {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    margin-right: 80px;
    width: 190px;
    height: 70px;
    padding: 5px 0;
    box-sizing: border-box;
    h5 {
      font-size: 16px;
      font-weight: 700;
      color: #848a9e;
    }
    p {
      font-size: 12px;
      color: #a2a6b8;
      i {
        display: inline-block;
        width: 14px;
        height: 16px;
        vertical-align: top;
        margin-right: 5px;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  img {
    width: 70px;
    height: 70px;
  }
}
.sj_content li .rwm {
  width: 100px;
  height: 100px;
  background: #e8e9ef;
  border-radius: 12px;
  img{
    width: 100%;
    height: 100%;
  }
}
//本页面公共样式
.xz_top {
  height: 70px;
}
.gyxz {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  img {
    width: 70px;
    height: 70px;
  }
  .rj_nr {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    margin-right: 60px;
    width: 220px;
    height: 70px;
    padding: 10px 0;
    box-sizing: border-box;
    h5 {
      font-size: 16px;
      font-weight: 700;
      color: #848a9e;
    }
    p {
      font-size: 12px;
      color: #a2a6b8;
    }
  }
  .ljxz {
    width: 100px;
    height: 36px;
    background: #3c7efe;
    border-radius: 18px;
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    line-height: 36px;
    box-shadow:0px 2px 12px #3c7efe;
    cursor: pointer;
    a{
      text-decoration: none;
      color: #fff;
    }
  }
}
//共用样式
.btt {
  width: 100%;
  height: 65px;
  padding: 25px 0 0 35px;
  .btts {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    span {
      display: inline-block;
      width: 4px;
      height: 16px;
      background: #3c7efe;
      border-radius: 2px;
      margin-right: 10px;
    }
    em {
      font-size: 14px;
    }
  }
}
</style>